import { Middleware } from 'redux';
import { RootState } from 'redux/root-reducer';
import AmpService from 'common/api/AmpService';
import { notify } from 'common/components/notifMessages/Notify';
import { downloading } from 'common/components/notifMessages/notifMessages';
import * as menu from 'redux/menu/menuActions';
import { ContentType } from 'common/api/models';
import getAssetDownloadName from 'common/utils/getAssetDownloadName';
import isClickAction from 'redux/menu/utils/isClickAction';
import isDriveAssetContext from 'redux/menu/utils/isDriveAssetContext';
import isSharedAssetContext from 'redux/menu/utils/isSharedAssetContext';
import MenuOption from 'redux/menu/enums/menuOption';
import isDriveMasterContractContext from 'redux/menu/utils/isDriveMasterContractContext';
import { downloadPDF } from 'modules/splitSheet/pdf/PDFSplitSheet';
import { isGqlSplitSheet } from 'common/api/utils';
import getSplitSheet from 'api/contracts/services/getSplitSheet';

const snackOp = {
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'left' as const,
  },
};

export const downloadAsset: Middleware<{}, RootState> = () => (next) => (action) => {
  if (isClickAction(action)) {
    const { context, option } = action.payload;

    if (option === MenuOption.DOWNLOAD) {
      if (isDriveAssetContext(context)) {
        const [asset] = context.data;

        if (typeof asset?.file?.media_url === 'string') {
          const toastId = notify.enqueueSnackbar(downloading, snackOp);
          AmpService.download(
            {
              contentType: asset.type_id,
              id: asset.id,
              name: getAssetDownloadName(asset),
              url: asset.file.media_url,
            },
            () => notify.closeSnackbar(toastId)
          );
        }
      }

      if (isSharedAssetContext(context)) {
        const [asset] = context.data;
        if (asset.type_id === ContentType.COLLECTION) {
          return;
        }

        if (!(asset as any).download_url) {
          notify.enqueueSnackbar('File is still processing', {
            variant: 'error',
          });

          return;
        }

        const { id, title, type_id } = asset;

        (async () => {
          if (id && title && type_id && (context.data[0] as any).download_url) {
            const toastId = notify.enqueueSnackbar(downloading, snackOp);
            AmpService.download(
              {
                contentType: type_id,
                id: Number(id),
                name: title,
                url: (context.data[0] as any).download_url,
              },
              () => notify.closeSnackbar(toastId)
            );
          }
        })();
      }

      if (isDriveMasterContractContext(context)) {
        const splitSheetObj = context.data.find(isGqlSplitSheet);
        if (splitSheetObj) getSplitSheet(`${splitSheetObj.id}`).then(downloadPDF);
      }

      return next(menu.close());
    }
  }

  return next(action);
};
