import React from 'react';
import { Document, Page, View, Text, pdf, StyleSheet, Link } from '@react-pdf/renderer';
import { IRestSplitSheet } from '../contracts/interfaces/IRestSplitSheet';
import StatusLabelText from 'modules/masters/components/approversSection/enums/statusLabelText';

const PDFSplitSheet = (data: IRestSplitSheet) => {
  const approver_headers = [
    'Name',
    'Contract Info',
    'Role',
    'Affiliation',
    'Ownership',
    'Status',
  ];
  const reviewer_headers = ['Name', 'Email', 'Approver to follow'];
  const styles = StyleSheet.create({
    rowView: {
      display: 'flex',
      flexDirection: 'row',
      borderTop: '1px solid #EEE',
      paddingTop: 8,
      paddingBottom: 8,
      textAlign: 'center',
      flexWrap: 'wrap',
      // flexShrink: 1,
    },
    viewMarginTop: {
      marginTop: '20px',
    },
    viewPaddingDocument: {
      padding: '5px',
    },
    approverRowText: {
      width: `${100 / approver_headers.length}%`,
      fontSize: 14,
    },
    emailText: {
      width: `${100 / approver_headers.length}%`,
      fontSize: 14,
      flex: 1,
    },
    reviewerRowText: {
      width: `${100 / reviewer_headers.length}%`,
      fontSize: 14,
    },
  });

  const returnHeaders = (headers: string[]) => {
    return (
      <>
        {headers.map((header_el, index) => (
          <Text
            key={index}
            style={{
              width: `${100 / headers.length}%`,
            }}
          >
            {header_el}
          </Text>
        ))}
      </>
    );
  };

  return (
    <Document>
      <Page size="A4" orientation="landscape">
        <View style={styles.viewPaddingDocument}>
          <Text>{data.title}</Text>
          <Text>Date: {new Date(data.date).toLocaleDateString()}</Text>
          <Text>Status: {StatusLabelText[data.state]}</Text>
          <View style={styles.viewMarginTop}>
            <Text>Approvers</Text>
            <View style={styles.rowView}>{returnHeaders(approver_headers)}</View>
            {data.approvers.map((data_el, index) => {
              return (
                <View style={styles.rowView}>
                  <Text style={styles.approverRowText}>{data_el.name}</Text>
                  <Text style={styles.approverRowText}>
                    <Link src={`mailto:${data_el.email}`}>{data_el.email}</Link>
                  </Text>
                  <Text style={styles.approverRowText}>{data_el.role_label}</Text>
                  <Text style={styles.approverRowText}>{data_el.affiliation}</Text>
                  <Text style={styles.approverRowText}>{data_el.ownership}</Text>
                  <Text style={styles.approverRowText}>
                    {data_el.accepted ? 'Yes' : 'No'}
                  </Text>
                </View>
              );
            })}
          </View>
          <View style={styles.viewMarginTop}>
            <Text>Reviwers</Text>
            <View style={styles.rowView}>{returnHeaders(reviewer_headers)}</View>
            {data.reviewers.map((data_el, index) => {
              return (
                <View style={styles.rowView}>
                  <Text style={styles.reviewerRowText}>{data_el.name}</Text>
                  <Text style={styles.reviewerRowText} wrap={false}>
                    <Link src={`mailto:${data_el.email}`}>{data_el.email}</Link>
                  </Text>
                  <Text style={styles.reviewerRowText}>
                    {data.approvers.find((app_el) => app_el.id === data_el.following_id)
                      ?.email || ''}
                  </Text>
                </View>
              );
            })}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export const downloadPDF = async (data: IRestSplitSheet) => {
  const blobFile = await pdf(PDFSplitSheet(data)).toBlob();
  saveAs(blobFile, `SPLIT_SHEET_${data.title}.pdf`);
};
